<template>
  <div>
    <b-container fluid>
      <b-row>
        <b-col cols="12" md="12" lg="12">
          <b-card header-tag="header" header-bg-variant="dark">
            <template #header>
              <h5 class="mb-0" style="color: #fff">
                <strong>{{ $store.state.table.konfirmasi }}</strong>
              </h5>
            </template>
            <b-row>
              <b-col cols="12" md="12" lg="12">
                <hr />
              </b-col>
            </b-row>
            <b-alert dismissible fade :show="showing" :variant="variant">{{
              msg
            }}</b-alert>

            <b-row>
              <b-col md="6" offset-md="6">
                <b-form-group
                  :label="$store.state.table.cari"
                  label-for="filter-input"
                  label-cols-md="3"
                  label-align-md="right"
                  label-size="md"
                >
                  <b-input-group size="md">
                    <b-form-input
                      id="filter-input"
                      v-model.lazy="filter"
                      type="search"
                      :placeholder="$store.state.table.cariHolder"
                    ></b-form-input>

                    <b-input-group-append>
                      <b-button
                        :disabled="!filter"
                        @click="filter = ''"
                        variant="danger"
                        >{{ $store.state.table.hapusCari }}</b-button
                      >
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" md="12" lg="12">
                <b-table
                  :items="items"
                  :fields="fields"
                  :current-page="currentPage"
                  :per-page="perPage"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  stacked="md"
                  responsive
                  show-empty
                  small
                  @filtered="onFiltered"
                  bordered
                  striped
                  hover
                  :busy="tableBusy"
                >
                  <template #cell(no)="item">
                    {{ item.index + 1 }}
                  </template>
                  <template #cell(actions)="item">
                    <b-button
                      variant="secondary"
                      class="mr-1"
                      v-c-tooltip.hover.click="'Print Surat'"
                      v-b-modal.modal-print
                      @click="setPrint(item.item)"
                      ><CIcon name="cil-print" />{{ item.actions }}</b-button
                    >

                    <b-button
                      variant="primary"
                      class="mr-1"
                      v-c-tooltip.hover.click="'Kirim Surat'"
                      @click="changeStatus(item.item)"
                      ><CIcon name="cil-print" />{{ item.actions }}</b-button
                    >

                    <b-button
                      v-if="item.item.statusKonfirmasi != 0"
                      variant="warning"
                      class="mr-1"
                      v-c-tooltip.hover.click="'Edit Data'"
                      v-b-modal.modal-edit
                      @click="data = item.item"
                      ><CIcon name="cil-pencil" /> {{ item.actions }}</b-button
                    >
                  </template>
                </b-table>
              </b-col>
            </b-row>

            <b-row>
              <b-col md="3">
                <b-form-group
                  :label="$store.state.table.perHalaman"
                  label-for="per-page-select"
                  label-cols-md="6"
                  label-align-md="left"
                  label-size="md"
                  style="background-color: "
                >
                  <b-form-select
                    id="per-page-select"
                    v-model="perPage"
                    :options="pageOptions"
                    size="sm"
                  ></b-form-select>
                </b-form-group>
              </b-col>
              <b-col md="5" offset-md="4">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  align="fill"
                  size="sm"
                ></b-pagination>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
    <modal-edit :data="data" @alertFromChild="triggerAlert($event)" />
    <ModalPrint :printData="printData" />
  </div>
</template>

<script>
import axios from "axios";
import { ipBackend } from "@/ipBackend.js";
import ModalEdit from "./modalEdit.vue";
import ModalPrint from "./modalPrint.vue";
import moment from "moment";
moment.locale("id");
export default {
  components: {
    ModalEdit,
    ModalPrint,
  },
  data() {
    return {
      showing: false,
      variant: "success",
      printData: null,
      msg: "",
      data: "",
      items: [],
      fields: [
        {
          key: "no",
          label: "No",
          sortDirection: "desc",
          sortable: true,
          class: "table-number text-center",
        },
        {
          key: "nomorSuratKonfirmasi",
          label: "No. Surat Konfirmasi",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "namaKaryawan",
          label: "Nama",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "statusnya",
          label: "Status",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "tglDibuat",
          label: "Tanggal Surat Dibuat",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "tglDibalas",
          label: "Tanggal Surat Dibalas",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "actions",
          label: "Actions",
          class: "table-option-4 text-center",
        },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      tableBusy: false,
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
  },
  mounted() {
    // Set the initial number of items
    this.getDatas();
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async getDatas() {
      let vm = this;
      vm.tableBusy = true;
      let listKonfirmasi = await axios.get(ipBackend + "suratKonfirmasi/list");
      vm.items = listKonfirmasi.data.data;
      for (let i = 0; i < vm.items.length; i++) {
        let x = vm.items[i];
        if (x.statusKonfirmasi == 0) {
          x.statusnya = "Belum Dikirim";
        } else if (x.statusKonfirmasi == 1) {
          x.statusnya = "Dikirim (Belum Dibalas)";
        } else if (x.statusKonfirmasi == 2) {
          x.statusnya = "Sudah Dibalas";
        }
        x.tglDibuat = moment(x.tanggalDibuatSurat).format("DD-MM-YYYY");
        if (x.tanggalDibalasSurat) {
          x.tglDibalas = moment(x.tanggalDibalasSurat).format("DD-MM-YYYY");
        }
      }
      console.log(listKonfirmasi, "ini items");
      vm.totalRows = vm.items.length;
      vm.tableBusy = false;
    },
    triggerAlert(event) {
      let vm = this;
      // vm.showing = event.showing;
      // vm.variant = event.variant;
      // vm.msg = event.msg;
      vm.$store.commit("set_alert", event);
      vm.getDatas();
      // setTimeout(() => {
      //   vm.showing = false;
      // }, 4000);
    },
    changeStatus(x) {
      let vm = this;
      // console.log(x);
      let data = {};
      data.id = x.suratKonfirmasiId;
      data.statusKonfirmasi = 1;
      axios
        .post(ipBackend + "suratKonfirmasi/update", data)
        .then((res) => {
          console.log(res, "ini ress");
          if (res.data.message == "sukses") {
            vm.variant = "success";
            vm.msg = "BERHASIL MENGIRIM SURAT KONFIRMASI";
            vm.showing = true;
            setTimeout(() => {
              vm.showing = false;
            }, 4000);
          } else {
            vm.variant = "danger";
            vm.msg = _.toUpper(res.data.message);
            vm.showing = true;
            setTimeout(() => {
              vm.showing = false;
            }, 4000);
          }
        })
        .catch((err) => {
          // console.log(err);
          vm.variant = "danger";
          vm.msg = "TERJADI KESALAHAN PADA SERVER";
          vm.showing = true;
          setTimeout(() => {
            vm.showing = false;
          }, 4000);
        });
    },
    print(data) {
      window.open(
        ipBackend + "poolKontrak/cetakSuratPerintah/" + data.poolKontrakId,
        "_blank"
      );
    },
    async setPrint(x) {
      console.log(x, "ini x");
      let vm = this;
      let printdata = await axios.get(
        ipBackend + "poolKontrak/pengantarYBS/" + x.dataKaryawanId
      );
      // console.log(printdata.data.data, "ini konfirmasinya boss");
      vm.printData = printdata.data.data[0];
      vm.printData.tanggal = moment().format("LL");
      vm.printData.tanggalLahirKaryawanPrint = moment(
        vm.printData.tanggalLahirKaryawan
      ).format("LL");
    },
  },
};
</script>
